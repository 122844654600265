import { useState } from 'react';
import { useSetAtom } from 'jotai';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button } from '@dop-ui/react/shared/ui/button';
import { Primitives } from '@dop-ui/react/shared/ui/dialog';
import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { ScheduleInfo } from '../../types';
import { deleteScheduleDialogOpenAtom } from '../../store/dialog_open_atom';
import { deleteCompanySchedule, QUERY_KEY } from '../../apis/schedule-manage';

type DeletionType =
  | 'DELETE_THIS_SCHEDULE_ONLY'
  | 'DELETE_ALL'
  | 'DELETE_FROM_NEXT_SCHEDULE'
  | 'DELETE_FROM_NEXT_YEAR';

interface Props {
  scheduleInfo: ScheduleInfo;
  onCompleteDelete: () => void;
}

export function Content({ scheduleInfo, onCompleteDelete }: Props) {
  const { t } = useTranslation('component');
  const setDialogOpen = useSetAtom(deleteScheduleDialogOpenAtom);
  const queryClient = useQueryClient();
  const toaster = useToastMessage();

  const [deletionType, setDeletionType] = useState<DeletionType>(
    'DELETE_THIS_SCHEDULE_ONLY',
  );

  const isRepeatScheduleExist = scheduleInfo.calendarRepeatType !== 'NONE';

  const deleteScheduleListMutation = useMutation({
    mutationFn: (info: {
      id: number;
      calendarDeletionType?: string;
      month: number;
      year: number;
    }) => deleteCompanySchedule(info),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      toaster.info(t('globalconfig.common.toastMessage.deleted'));
      setDialogOpen(false);
      onCompleteDelete();
    },
    onError: (error) => {
      toaster.warning(error.message);
    },
  });

  const onClickDelete = () => {
    let year = -1;
    let month = -1;

    if (
      scheduleInfo.dateType === 'DATE_SPECIFICATION' &&
      scheduleInfo.startDate
    ) {
      const date = new Date(scheduleInfo.startDate);
      year = date.getFullYear();
      month = date.getMonth() + 1;
    }

    if (scheduleInfo.dateType === 'DAY_SPECIFICATION') {
      year = scheduleInfo.year ?? -1;
      month = scheduleInfo.month ?? -1;
    }

    deleteScheduleListMutation.mutate({
      id: scheduleInfo.id ?? -1,
      year: year,
      month: month,
      calendarDeletionType: isRepeatScheduleExist ? deletionType : undefined,
    });
  };

  return (
    <>
      <p className="text-[#363636] //text-[--color-text-level1] text-[20px] font-[600] leading-[30px]">
        {t('globalconfig.basicManagement.companySchedule.schedule.delete')}
      </p>
      <div className="mt-[16px]">
        <p className="text-[#363636] //text-[--color-text-level1] text-[14px] font-[400]">
          {isRepeatScheduleExist
            ? t(
                'globalconfig.basicManagement.companySchedule.schedule.delete.confirm2',
              )
            : t(
                'globalconfig.basicManagement.companySchedule.schedule.delete.confirm',
              )}
        </p>
        <p className="text-[#363636] //text-[--color-text-level1] text-[14px] font-[400]">
          {t(
            'globalconfig.basicManagement.companySchedule.schedule.delete.warning',
          )}
        </p>
        {isRepeatScheduleExist && (
          <div className="mt-[16px] flex flex-col gap-[8px]">
            <div>
              <input
                id={'deletion_type_delete_this_schedule_only'}
                className="size-[16px] rounded-[16px] border border-solid border-[#C5C5C5] bg-white checked:border-[#363636] checked:accent-[#363636]"
                type="radio"
                name="deletionType"
                value="DELETE_THIS_SCHEDULE_ONLY"
                title={t(
                  'globalconfig.basicManagement.companySchedule.schedule.delete.type.onlyThisSchedule',
                )}
                checked={deletionType === 'DELETE_THIS_SCHEDULE_ONLY'}
                onChange={() => setDeletionType('DELETE_THIS_SCHEDULE_ONLY')}
              />
              <label
                className="ml-[4px]"
                htmlFor="deletion_type_delete_this_schedule_only"
              >
                {t(
                  'globalconfig.basicManagement.companySchedule.schedule.delete.type.onlyThisSchedule',
                )}
              </label>
            </div>
            {scheduleInfo.scheduleType === 'ANNIVERSARY' && (
              <div>
                <input
                  id={'deletion_type_delete_all'}
                  className="size-[16px] rounded-[16px] border border-solid border-[#C5C5C5] bg-white checked:border-[#363636] checked:accent-[#363636]"
                  type="radio"
                  name="deletionType"
                  value="DELETE_ALL"
                  title={t(
                    'globalconfig.basicManagement.companySchedule.schedule.delete.type.all',
                  )}
                  checked={deletionType === 'DELETE_ALL'}
                  onChange={() => setDeletionType('DELETE_ALL')}
                />
                <label className="ml-[4px]" htmlFor="deletion_type_delete_all">
                  {t(
                    'globalconfig.basicManagement.companySchedule.schedule.delete.type.all',
                  )}
                </label>
              </div>
            )}
            <div>
              <input
                id={'deletion_type_delete_from_next_schedule'}
                className="size-[16px] rounded-[16px] border border-solid border-[#C5C5C5] bg-white checked:border-[#363636] checked:accent-[#363636]"
                type="radio"
                name="deletionType"
                value="DELETE_FROM_NEXT_SCHEDULE"
                title={t(
                  'globalconfig.basicManagement.companySchedule.schedule.delete.type.fromNextSchedule',
                )}
                checked={deletionType === 'DELETE_FROM_NEXT_SCHEDULE'}
                onChange={() => setDeletionType('DELETE_FROM_NEXT_SCHEDULE')}
              />
              <label
                className="ml-[4px]"
                htmlFor="deletion_type_delete_from_next_schedule"
              >
                {t(
                  'globalconfig.basicManagement.companySchedule.schedule.delete.type.fromNextSchedule',
                )}
              </label>
            </div>
            <div>
              <input
                id={'deletion_type_delete_from_next_year'}
                className="size-[16px] rounded-[16px] border border-solid border-[#C5C5C5] bg-white checked:border-[#363636] checked:accent-[#363636]"
                type="radio"
                name="deletionType"
                value="DELETE_FROM_NEXT_YEAR"
                title={t(
                  'globalconfig.basicManagement.companySchedule.schedule.delete.type.afterThisYear',
                )}
                checked={deletionType === 'DELETE_FROM_NEXT_YEAR'}
                onChange={() => setDeletionType('DELETE_FROM_NEXT_YEAR')}
              />
              <label
                className="ml-[4px]"
                htmlFor="deletion_type_delete_from_next_year"
              >
                {t(
                  'globalconfig.basicManagement.companySchedule.schedule.delete.type.afterThisYear',
                )}
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-end mt-[24px] gap-[12px]">
        <Primitives.Close asChild>
          <Button size="medium" shape="rect" colorset="level2" variant="ghost">
            <p className="font-[500]">{t('dialog.cancel')}</p>
          </Button>
        </Primitives.Close>
        <Button
          size="medium"
          shape="rect"
          colorset="level1"
          variant="solid"
          onClick={onClickDelete}
        >
          <p className="font-[500]">{t('dialog.delete')}</p>
        </Button>
      </div>
    </>
  );
}
