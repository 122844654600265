import * as React from 'react';
import { AdminTableData, TableSortType } from './AdminTable';

export interface AppManager {
  userId: number;
  isMaster?: boolean;
  companyAppIds: number[];
}

export interface IContextValues {
  isMaster: boolean;
  selectedAppId: string;
  selectedAppCode: string;
  selectedAppName: string;
  currentMember: AppManager | undefined;
  selectedTablePage: number;
  adminTableData: AdminTableData | undefined;
  tableSortType: TableSortType | undefined;
}

export interface IContextActions {
  setMasterState: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedAppId: React.Dispatch<React.SetStateAction<string>>;
  setSelectedAppCode: React.Dispatch<React.SetStateAction<string>>;
  setSelectedAppName: React.Dispatch<React.SetStateAction<string>>;
  setCurrentMember: React.Dispatch<
    React.SetStateAction<AppManager | undefined>
  >;
  setSelectedTablePage: React.Dispatch<React.SetStateAction<number>>;
  setAdminTableData: React.Dispatch<
    React.SetStateAction<AdminTableData | undefined>
  >;
  setTableSortType: React.Dispatch<
    React.SetStateAction<TableSortType | undefined>
  >;
}

export const ValuesContext = React.createContext<IContextValues>({
  isMaster: false,
  selectedAppId: '',
  selectedAppCode: '',
  selectedAppName: '',
  selectedTablePage: 1,
  currentMember: undefined,
  adminTableData: undefined,
  tableSortType: undefined,
});

export const ActionsContext = React.createContext<IContextActions>({
  setMasterState: () => undefined,
  setSelectedAppId: () => undefined,
  setSelectedAppCode: () => undefined,
  setSelectedAppName: () => undefined,
  setSelectedTablePage: () => undefined,
  setCurrentMember: () => undefined,
  setAdminTableData: () => undefined,
  setTableSortType: () => undefined,
});

export interface Props {
  title?: string;
  currentMember?: AppManager | undefined;
}

export function Provider({
  title,
  currentMember: defaultCurrentMember,
  children,
}: React.PropsWithChildren<Props>) {
  const [selectedAppId, setSelectedAppId] = React.useState('');
  const [selectedAppCode, setSelectedAppCode] = React.useState('');
  const [selectedAppName, setSelectedAppName] = React.useState(title ?? '');
  const [selectedTablePage, setSelectedTablePage] = React.useState(0);
  const [currentMember, setCurrentMember] =
    React.useState(defaultCurrentMember);
  const [adminTableData, setAdminTableData] = React.useState();
  const [tableSortType, setTableSortType] = React.useState();
  const [isMaster, setMasterState] = React.useState(false);

  const contextValues = {
    isMaster,
    selectedAppId,
    selectedAppCode,
    selectedTablePage,
    currentMember,
    adminTableData,
    tableSortType,
    selectedAppName,
  };

  const contextActions: IContextActions = {
    setMasterState,
    setSelectedAppId,
    setSelectedAppCode,
    setSelectedTablePage,
    setCurrentMember,
    setAdminTableData: setAdminTableData as React.Dispatch<
      React.SetStateAction<AdminTableData | undefined>
    >,
    setTableSortType: setTableSortType as React.Dispatch<
      React.SetStateAction<TableSortType | undefined>
    >,
    setSelectedAppName,
  };

  return (
    <ActionsContext.Provider value={contextActions}>
      <ValuesContext.Provider value={contextValues}>
        {children}
      </ValuesContext.Provider>
    </ActionsContext.Provider>
  );
}

export const useValuesContext = () => React.useContext(ValuesContext);
export const useActionsContext = () => React.useContext(ActionsContext);

export const useContext = () => {
  const values = useValuesContext();
  const actions = useActionsContext();

  return {
    ...values,
    ...actions,
  };
};
