'use client';
import '@daouoffice/design/dist/components.css';
import '@daouoffice/design/dist/pages/management/preference_auth.css';
import '@daouoffice/design/dist/template/snb.css';
import { Avatar } from '@daouoffice/ui';
import * as Dialog from '@daouoffice/ui/lib/foundation/Dialog';
import { TrashCanIcon } from '@daouoffice/ui/lib/icons/dop/24';
import * as TablePrimitives from '@daouoffice/ui/lib/labs/Table';
import { columnDefGenerator } from '@daouoffice/ui/lib/labs/Table';
import {
  SortChevron,
  type Direction,
} from '@daouoffice/ui/lib/labs/Table/utils/columnDefGenerator';
import { useSessionContext } from '@dop-ui/react/features/authenticate';
import { useMessageDialog } from '@dop-ui/react/features/message-dialog';
import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { formatDate } from '@dop-ui/react/shared/lib/date';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Button } from '@dop-ui/react/shared/ui/button';
import { PlusIcon } from '@heroicons/react/24/outline';
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { clsx } from 'clsx';
import { useEffect, useState } from 'react';
import OrgChart from '../../../../../../OrgChart';
import { NodeProps } from '../../../../../../OrgChart/interface';
import * as appCodeApis from '../../../../../parts/Menu/apis/managerAppCodes';
import { useContextValues } from '../authorization/manager/SubTabGroup/Context';
import { useActionsContext, useValuesContext } from '../Context';
import * as adminTableDeleteMutation from './api/adminTableDeleteMutation';
import * as adminTableInsertMutation from './api/adminTableInsertMutation';
import * as adminTableQueries from './api/adminTableQueries';
import {
  AdminTableData,
  AdminTableType,
  SortType,
  TableRowInfo,
} from './index';

export interface Props {
  type: AdminTableType;
}

// TODO: Table 구현체 Tanstack Table로 변경 필요.
export function AdminTableClient({ type }: Props) {
  const { confirm } = useMessageDialog();
  const { me } = useSessionContext();
  const { info, warning } = useToastMessage();
  const { selectedAppId, currentMember, adminTableData, selectedAppCode } =
    useValuesContext();
  const { setCurrentMember, setAdminTableData, setMasterState } =
    useActionsContext();
  const { setEditMode } = useContextValues();
  const { t } = useTranslation('component');
  const [selectedList, setSelectedList] = useState<TableRowInfo[]>([]);
  const [orderType, setOrderType] = useState('');
  const [direction, setDirection] = useState<SortType>('ascend');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [keyword, setKeyword] = useState<string>('');
  const [size, setSize] = useState<number>(10);
  const [isAppMangerAdded, setIsAppMangerAdded] = useState<boolean>(false);
  const [orgChartOpenState, setOrgChartOpenState] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const [clickedRow, setClickedRow] = useState<number | null>(null);
  const {
    data: initialData,
    error,
    isFetching,
    refetch,
  } = useSuspenseQuery({
    queryFn: async () => {
      return await adminTableQueries.fetchTableData(
        currentPage,
        type,
        selectedAppId,
        keyword,
        { name: orderType, sortType: direction },
        size,
      );
    },
    queryKey: [adminTableQueries.QUERY_KEY],
  });
  const addMutation = useMutation({
    mutationFn: async (ids: number[]) =>
      await Promise.all(
        ids.map((id) => {
          return selectedAppId === 'masterApp'
            ? adminTableInsertMutation.postMasterDataInfo(id)
            : adminTableInsertMutation.putTableDataInfo(selectedAppId, [id]);
        }),
      ),
    onSuccess: async () => {
      info(t('globalconfig.secureManagement.adminManagement.toast.addMember'));
      await queryClient.invalidateQueries({
        queryKey: [adminTableQueries.QUERY_KEY],
      });
    },
    onError: (e) => warning(e.message),
  });

  const deleteMutation = useMutation({
    mutationFn: async () => {
      if (type === 'app') {
        return selectedAppId === 'masterApp'
          ? await adminTableDeleteMutation.deleteMasterDataInfo(
              selectedList.map((member) => member.userId),
            )
          : await adminTableDeleteMutation.deleteTableDataInfo(
              selectedAppId,
              selectedList.map((member) => member.userId),
            );
      } else {
        return await adminTableDeleteMutation.deleteAppManagerDataInfo(
          selectedList.map((member) => member.userId),
        );
      }
    },
    onSuccess: async () => {
      info(t('globalconfig.secureManagement.adminManagement.toast.delete'));
      await queryClient.invalidateQueries({
        queryKey: [adminTableQueries.QUERY_KEY],
      });
      await selfDeleteHandler();
    },
    onError: (e) => warning(e.message),
  });

  const selfDeleteHandler = async () => {
    if (selectedList.some((member) => member.userId === me?.id)) {
      await queryClient.invalidateQueries({
        queryKey: [appCodeApis.QUERY_KEY],
      });

      if (
        selectedAppCode === 'dop-default-security-management' ||
        selectedAppId === 'masterApp'
      ) {
        window.location.reload();
      }
    }
  };

  const getDirection = (direction: SortType) =>
    direction === 'ascend' ? 'asc' : 'desc';

  const onSortChangeHandler = (direction: Direction, field: string) => {
    setOrderType(field);
    setDirection(direction === 'asc' ? 'ascend' : 'descend');
  };

  const addMemberHandler = (members: NodeProps[]) => {
    if (members.length > 0) {
      if (type === 'app') {
        console.log(members);
        addMutation.mutate(
          members
            .map((member) => member?.userId)
            .filter((id): id is number => id !== undefined),
        );
      } else {
        const newMember = {
          type: 'body',
          name: members.at(0)?.name,
          account: members.at(0)?.loginId,
          userId: members.at(0)?.userId,
          position: members.at(0)?.position,
          department: members.at(0)?.departmentName,
          appointment: members.at(0)?.duty,
          date: formatDate({ date: new Date(), format: 'YYYY-MM-DD' }),
          isMaster: false,
          appList: [],
        };
        if (
          !adminTableData?.data.some(
            (member) => member.userId === newMember.userId,
          )
        ) {
          setIsAppMangerAdded(true);
          setMasterState(false);
          setAdminTableData({
            ...adminTableData,
            data: [newMember, ...(adminTableData?.data?.slice(0, -1) ?? [])],
          } as AdminTableData);
          setCurrentMember({
            userId: newMember.userId ?? -1,
            isMaster: false,
            companyAppIds: [],
          });
        } else {
          warning(
            t('globalconfig.secureManagement.adminManagement.toast.added'),
          );
        }
      }
    }
    setOrgChartOpenState(false);
  };

  const deleteMemberHandler = () => {
    const handleConfirm = async () => {
      const requestConfirm = (
        <div className="w-full !px-4 flex flex-col gap-4">
          <h2 className="text-[18px] text-black font-medium">
            {t(
              'globalconfig.secureManagement.adminManagement.messageDialog.deleteAdmin.title',
            )}
          </h2>
          <span className="text-[14px] text-black font-normal whitespace-normal">
            {t(
              'globalconfig.secureManagement.adminManagement.messageDialog.deleteAdmin.contents',
              { adminCount: String(selectedList.length) },
            )}
          </span>
        </div>
      );

      return await confirm(requestConfirm);
    };

    if (selectedList.length > 0)
      handleConfirm()
        .then((res) => res && deleteMutation.mutate())
        .catch(console.error);
  };

  const memberClickHandler = (index: number) => {
    setClickedRow(index);
    setEditMode(true);
    setMasterState(adminTableData?.data[index]?.isMaster ?? false);
    adminTableData?.data[index]?.userId &&
      adminTableData?.data[index].appList &&
      setCurrentMember({
        userId: adminTableData?.data[index]?.userId,
        isMaster: adminTableData?.data[index]?.isMaster,
        companyAppIds: adminTableData?.data[index].appList,
      });
  };

  const columnDefs: ColumnDef<TableRowInfo>[] = [
    {
      accessorKey: 'name',
      id: 'name',
      header: () => (
        <div className="flex items-center py-3 justify-center gap-1">
          {t('globalconfig.secureManagement.adminManagement.table.header.name')}
          <SortChevron
            filterValue={'name'}
            selectedValue={orderType}
            direction={getDirection(direction)}
            onChange={onSortChangeHandler}
          />
        </div>
      ),
      cell: (value: CellContext<TableRowInfo, unknown>) => (
        <div className="flex items-center ps-5 py-3 text-center justify-start gap-2 overflow-hidden whitespace-nowrap text-elipsis">
          <Avatar
            size={24}
            alt={value.row.original.name}
            src={value.row.original.imgSrc}
          />
          <span className="text-[#363636] text-[14px] font-normal leading-[14px] -tracking-[0.28px]">
            {value.row.original.name}
          </span>
        </div>
      ),
    },
    columnDefGenerator<TableRowInfo>(
      'account',
      t('globalconfig.secureManagement.adminManagement.table.header.account'),
      'account',
      orderType,
      onSortChangeHandler,
      getDirection(direction),
    ),
    columnDefGenerator<TableRowInfo>(
      'position',
      t('globalconfig.secureManagement.adminManagement.table.header.position'),
      'position',
      orderType,
      onSortChangeHandler,
      getDirection(direction),
    ),
    columnDefGenerator<TableRowInfo>(
      'department',
      t(
        'globalconfig.secureManagement.adminManagement.table.header.department',
      ),
    ),
    columnDefGenerator<TableRowInfo>(
      'appointment',
      t('globalconfig.secureManagement.adminManagement.table.header.duty'),
    ),
    columnDefGenerator<TableRowInfo>(
      'date',
      t('globalconfig.secureManagement.adminManagement.table.header.created'),
      'date',
      orderType,
      onSortChangeHandler,
      getDirection(direction),
    ),
    {
      accessorKey: 'appList',
      id: 'appList',
      header: () => (
        <div className="flex items-center py-3 justify-center gap-1">
          {t(
            'globalconfig.secureManagement.adminManagement.table.header.management.state',
          )}
        </div>
      ),
      cell: (value: CellContext<TableRowInfo, unknown>) => (
        <div className="py-3 text-center">
          {value.row.original.isMaster ? (
            <span className="text-[#3A87FD] text-[14px] font-semibold leading-[14px] -tracking-[0.28px] whitespace-nowrap overflow-hidden text-ellipsis">
              {t('globalconfig.secureManagement.adminManagement.master')}
            </span>
          ) : (
            <span className="text-[#363636] text-[14px] font-normal leading-[14px] -tracking-[0.28px] whitespace-nowrap overflow-hidden text-ellipsis">
              {t(
                'globalconfig.secureManagement.adminManagement.table.managementState',
                { appCount: String(value.row.original.appList?.length) },
              )}
            </span>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    refetch().catch(console.error);
  }, [
    size,
    orderType,
    direction,
    currentPage,
    type,
    selectedAppId,
    keyword,
    refetch,
  ]);

  useEffect(() => {
    type === 'management' && setCurrentMember(undefined);
  }, [type]);

  useEffect(() => {
    setCurrentPage(0);
  }, [keyword, size]);

  useEffect(() => {
    setAdminTableData(initialData);
  }, [isFetching]);

  // 멤버 추가 시, 첫번째 리스트에 멤버 추가되고 나서 해당 멤버를 클릭.
  useEffect(() => {
    if (isAppMangerAdded) {
      memberClickHandler(0);
      setIsAppMangerAdded(false);
    }
  }, [adminTableData?.data]);

  useEffect(() => {
    if (currentMember === null) setClickedRow(null);
  }, [currentMember]);

  useEffect(() => {
    setCurrentPage(0);
  }, [selectedAppId]);

  if (error) {
    return <></>;
  }

  console.log('test', Number(me?.companyId));

  return (
    <>
      {selectedAppId || type === 'management' ? (
        <TablePrimitives.Root<TableRowInfo>
          className="w-full"
          columnDefs={columnDefs}
          contents={adminTableData?.data ?? []}
          onClickRow={type === 'app' ? undefined : memberClickHandler}
          onSelectContents={setSelectedList}
          selectable
        >
          <div className="flex justify-between mb-2">
            <div className="flex flex-col">
              <TablePrimitives.SearchBar
                className="inline-block w-[240px] h-[40px]"
                keyword={keyword}
                onKeywordChange={setKeyword}
              />
              {keyword && (
                <div className="pt-3 text-[#81A9FC]">{`${adminTableData?.data.length}${t('globalconfig.common.table.search.result')}`}</div>
              )}
            </div>
            <div className="flex items-end gap-2">
              <Dialog.Root
                modal={false}
                open={orgChartOpenState}
                onOpenChange={setOrgChartOpenState}
              >
                <Dialog.Trigger>
                  <Button className="flex items-center gap-1">
                    <PlusIcon className="size-6" />
                    <span className="font-normal leading-[150%] -tracking-[0.28px] text-[14px]">
                      {t(
                        'globalconfig.secureManagement.adminManagement.table.add.member',
                      )}
                    </span>
                  </Button>
                </Dialog.Trigger>
                <Dialog.Content size="resizeable">
                  <OrgChart
                    companyId={
                      typeof Number(me?.companyId) === 'number'
                        ? Number(me?.companyId)
                        : undefined
                    }
                    type="MEMBER"
                    style={{
                      height: '680px',
                      top: '60px',
                      right: '30px',
                      left: 'auto',
                    }}
                    onConfirm={(nodes: NodeProps[]) =>
                      type === 'app' && addMemberHandler([...nodes])
                    }
                    onNodeClick={(node: NodeProps) =>
                      type === 'management' && addMemberHandler([node])
                    }
                    useMultiSelect={type === 'app' ? true : false}
                    onClose={() => setOrgChartOpenState(false)}
                  />
                </Dialog.Content>
              </Dialog.Root>
              <Button
                className={clsx('flex items-center gap-1', {
                  'text-[#D8D8D8]': selectedList.length === 0,
                })}
                onClick={deleteMemberHandler}
                disabled={selectedList.length === 0}
              >
                <TrashCanIcon />
                <span className="font-normal leading-[150%] -tracking-[0.28px] text-[14px]">
                  {t(
                    'globalconfig.secureManagement.adminManagement.table.delete.member',
                  )}
                </span>
              </Button>
              <TablePrimitives.SizeSelector
                className="w-[52px]"
                tableSize={size}
                options={[10, 20, 30]}
                onChangeTableSize={setSize}
              />
            </div>
          </div>
          <TablePrimitives.Contents
            defaultClickRowIndex={clickedRow}
            styleType={type === 'management' ? 'clickRow' : undefined}
          />
          <TablePrimitives.Pagination
            className="mt-[16px]"
            currentPage={currentPage ?? 0}
            totalPage={adminTableData?.totalPage ?? 0}
            onNextPage={setCurrentPage}
            onPreviousPage={setCurrentPage}
            onClickPage={setCurrentPage}
            onFirstPage={() => setCurrentPage(0)}
            onLastPage={() =>
              setCurrentPage((adminTableData?.totalPage ?? 1) - 1)
            }
          />
        </TablePrimitives.Root>
      ) : (
        <div className="content_page w-full">
          <div className="section_group h-full">
            <div className="section grid place-content-center">
              {t(
                'globalconfig.secureManagement.adminManagement.table.select.app',
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AdminTableClient;
