import * as Dialog from '@daouoffice/ui/lib/foundation/Dialog';
import { FolderUpIcon } from '@daouoffice/ui/lib/icons/dop/24';
import { useMessageDialog } from '@dop-ui/react/features/message-dialog';
import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Button } from '@dop-ui/react/shared/ui/button';
import {
  ArrowDownTrayIcon,
  ArrowLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import { QueryClient, useMutation } from '@tanstack/react-query';
import { clsx } from 'clsx';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { formatFileNameWithDate } from '../../../../../../../../lib/utils/date/withLocale';
import OrgChart from '../../../../../../../OrgChart';
import { NodeProps } from '../../../../../../../OrgChart/interface';
import * as appTransferMutation from '../../../api/app-transfer-mutation';
import * as appDataQuery from '../../../api/appDataQuery';
import { dataDownloadQuery } from '../../../api/dataDownloadQuery';
import { AppType } from '../../../types';
import { useContext } from '../../Provider';
import { TransferConfirmDialog } from './transfer-confirm-dialog';

export function Header() {
  const queryClient = new QueryClient();
  const { confirm } = useMessageDialog();
  const {
    selectedAppType,
    deptId,
    deptName,
    selectedRows,
    setSelectedAppType,
  } = useContext();
  const { t } = useTranslation('component');
  const isDisabled = selectedRows.length === 0;
  const [deptSelectOpenState, setDeptSelectOpenState] = useState(false);
  const [, setDesignatedDeptId] = useState<number>();
  const [, setDesignatedDeptName] = useState<string>();
  const { info, warning } = useToastMessage();
  const transferMutation = useMutation({
    mutationKey: [appTransferMutation.MUTATION_KEY, selectedAppType, deptId],
    mutationFn: async (targetDeptId: number) => {
      return await appTransferMutation.transferApp(
        deptId,
        targetDeptId,
        selectedAppType ?? 'board',
        selectedRows
          ?.filter((row) => row.id !== undefined)
          ?.map((row) =>
            row.id ? (row.type === 'DRAFT' ? 'DRAFT' : row.id.toString()) : '',
          ),
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [appDataQuery.QUERY_KEY],
      });
      info(
        t('globalconfig.dataManagement.deptManagement.toast.transferComplete'),
      );
    },
    onError: () =>
      warning(
        t('globalconfig.dataManagement.deptManagement.toast.transferFailed'),
      ),
  });

  const titleTextRecord: Record<AppType, string> = {
    approval: t('globalconfig.dataManagement.deptManagement.approval.title'),
    board: t('globalconfig.dataManagement.deptManagement.board.title'),
    report: t('globalconfig.dataManagement.deptManagement.report.title'),
    group: t('globalconfig.dataManagement.deptManagement.group.title'),
  };

  const hasDefaultApprovalFolders = selectedRows.some(
    (row) => row.type === 'DRAFT',
  );

  const contentsTextRecord: Record<AppType, string> = {
    approval: hasDefaultApprovalFolders
      ? t(
          'globalconfig.dataManagement.deptManagement.dialog.transferContents.approval',
        )
      : t(
          'globalconfig.dataManagement.deptManagement.dialog.transferContents.approval.draft',
        ),
    board: t(
      'globalconfig.dataManagement.deptManagement.dialog.transferContents.board',
    ),
    report: t(
      'globalconfig.dataManagement.deptManagement.dialog.transferContents.report',
    ),
    group: t(
      'globalconfig.dataManagement.deptManagement.dialog.transferContents.contact',
    ),
  };

  const transferTextRecord: Record<AppType, string> = {
    approval: t('globalconfig.dataManagement.deptManagement.moveApproval'),
    board: t('globalconfig.dataManagement.deptManagement.moveBoard'),
    report: t('globalconfig.dataManagement.deptManagement.moveReport'),
    group: t('globalconfig.dataManagement.deptManagement.moveGroup'),
  };

  const onClickDownloadButtonHandler = async () => {
    try {
      await queryClient
        .fetchQuery({
          queryKey: ['dataDownloadQuery', deptId, selectedAppType],
          queryFn: () => dataDownloadQuery(deptId, selectedAppType),
        })
        .then((blob: Blob | undefined) => blob && blobHandler(blob));
    } catch (error) {
      console.error(error);
    }
  };

  const blobHandler = (blob: Blob) => {
    const blobURL = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobURL;
    link.download = formatFileNameWithDate(
      selectedAppType === 'approval'
        ? t(
            'globalconfig.dataManagement.deptManagement.downloadApprovalFileTitle',
          )
        : selectedAppType === 'board'
          ? t(
              'globalconfig.dataManagement.deptManagement.downloadBoardFileTitle',
            )
          : selectedAppType === 'report'
            ? t(
                'globalconfig.dataManagement.deptManagement.downloadReportFileTitle',
              )
            : t(
                'globalconfig.dataManagement.deptManagement.downloadContactFileTitle',
              ),
      new Date(),
      'csv',
    );
    link.click();
  };

  const nodeSelectHandler = async (node: NodeProps) => {
    setDeptSelectOpenState(false);
    const selectedNodeName =
      node.nodeType === 'DEPARTMENT'
        ? node.name
        : node?.departmentName ?? node.name;
    setDesignatedDeptName(selectedNodeName);
    console.log('seleectedNode', node);
    node.departmentId && setDesignatedDeptId(node.departmentId);
    if (
      await confirm(
        <TransferConfirmDialog
          title={transferTextRecord[selectedAppType ?? 'approval']}
          contents={contentsTextRecord[selectedAppType ?? 'approval'].replace(
            '{targetDeptName}',
            node?.departmentName ?? node?.name ?? '',
          )}
        />,
      )
    ) {
      node?.id && transferMutation.mutate(node?.id);
    }
  };

  const titleBlock = (
    <button
      className="flex items-center"
      onClick={() => setSelectedAppType(undefined)}
    >
      <ArrowLeftIcon className="w-6 h-6" />
      <h2 className="ms-2 font-medium text-[22px] -tracking-[0.88px] mr-3">
        {selectedAppType && titleTextRecord[selectedAppType]}
      </h2>
      <hr className="!m-0 w-[1px] h-3 bg-[#E2E5E9]" />
      <span className="font-normal ml-3 text-base text-[#AAA]">{deptName}</span>
    </button>
  );

  const deptSelectBlock = (
    <Dialog.Root
      open={deptSelectOpenState}
      onOpenChange={setDeptSelectOpenState}
    >
      <Dialog.Trigger>
        <Button
          size="md"
          shape="rect"
          variant="outline"
          colorset="level2"
          disabled={selectedRows.length === 0}
          onClick={() =>
            selectedRows.length > 0
              ? setDeptSelectOpenState(true)
              : setDeptSelectOpenState(false)
          }
        >
          <FolderUpIcon
            className={clsx('size-5', {
              'stroke-[#AAAAAA]': isDisabled,
              'stroke-[#363636]': !isDisabled,
            })}
          />
          {selectedAppType && transferTextRecord[selectedAppType]}
        </Button>
      </Dialog.Trigger>
      <Dialog.Content size="resizeable">
        <OrgChart
          type="DEPARTMENT"
          onClose={() => setDeptSelectOpenState(false)}
          style={{
            top: '256px',
            right: '92px',
            left: 'auto',
            bottom: '18px',
            width: '300px',
          }}
          useFooter={false}
          onNodeClick={(node) => {
            void nodeSelectHandler(node);
          }}
          useMultiSelect={false}
        />
      </Dialog.Content>
    </Dialog.Root>
  );

  const actionButtonListBlock = (
    <div className="flex items-center gap-2">
      {deptSelectBlock}
      <Button
        colorset="level2"
        variant="outline"
        onClick={() => void onClickDownloadButtonHandler()}
        size="md"
        shape="rect"
      >
        <ArrowDownTrayIcon className="size-4 stroke-[#363636]" />
        {t('globalconfig.common.button.downloadList.title')}
      </Button>
    </div>
  );

  const approvalRouteBlock = (
    <div className="flex items-center gap-2">
      <span className="text-sm text-[#AAA] font-normal -tracking-[0.28px]">
        {t('globalconfig.dataManagement.deptManagement.approval.help')}
      </span>
      {/* TODO: 바로가기 URL 생성시 추가 필요 */}
      <Link
        className="flex h-6 py-[2px] ps-2 pe-1 items-center bg-white border border-[#DEDEDE] rounded-lg gap-[2px]"
        href={''}
        onClick={() => warning('준비 중인 기능입니다.')}
      >
        {t('globalconfig.common.button.shortcut.title')}
        {<ChevronRightIcon className="w-3 h-3 stroke-[#A3A3A3]" />}
      </Link>
    </div>
  );

  useEffect(() => {
    selectedRows.length === 0 && setDeptSelectOpenState(false);
  }, [setDeptSelectOpenState, selectedRows]);

  return (
    <>
      <div className="flex items-center justify-between">
        {titleBlock}
        {actionButtonListBlock}
      </div>
      {selectedAppType === 'approval' && approvalRouteBlock}
    </>
  );
}

export default Header;
