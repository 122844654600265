/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access  */
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import { Switch } from '@dop-ui/react/shared/ui/switch';
import { Button } from '@dop-ui/react/shared/ui/button';
import {
  Templates,
  useToastMessage,
} from '@dop-ui/react/features/toast-message';
import * as Dext5Editor from '@dop-ui/react/widgets/dext-editor';
import { PopupAnnouncementDialog } from '@dop-ui/react/widgets/popup-announcement';
import { FileItem } from '@dop-ui/react/shared/ui/file-item';
import FormField from './components/form-field';
import FormAnnouncementPeroid from './form-announcement-period';
import FormAnnouncementTarget from './form-announcement-target';
import { createPopupAnnouncement } from '../apis/create-popup-announcement';
import { updatePopupAnnouncement } from '../apis/update-popup-announcement';
import * as getPopupAnnouncementQueries from '../apis/get-popup-announcement';
import * as getPopupAnnouncementListQueries from '../apis/get-popup-announcement-list';
import {
  uploadCommonFile,
  UploadResponse,
} from '@dop-ui/react/features/file-upload';
import { isContentNeedsSaveAtom } from '../../../../store/isContentNeedsSaveAtom';

import { usePopupAnnouncementContext } from '../context';
import {
  DEFAULT_POPUP_FORM,
  POPUP_DEVICES,
  POPUP_OPTIONS,
  POPUP_DICTIONARY,
} from '../constants';
import type { IPopupAnnouncement, IPopupAnnouncementForm } from '../types';
import { convertToForm } from '../utils';
import { ClipIcon, CloseIcon } from '@dop-ui/icons/react/dop/24';

import styles from './styles.module.css';

function PopupAnnouncementForm() {
  const editorId = 'popup-announcement-create';
  const { updateIdx, setPath, setUpdateIdx } = usePopupAnnouncementContext();
  const { t } = useTranslation('component');
  const toaster = useToastMessage();
  const queryClient = useQueryClient();
  const [, setIsNeedSave] = useAtom(isContentNeedsSaveAtom);

  const { data: popupAnnouncement } = useQuery({
    queryKey: [getPopupAnnouncementQueries.QUERY_KEY, updateIdx],
    queryFn: () => getPopupAnnouncementQueries.getPopupAnnouncement(updateIdx!),
    enabled: !!updateIdx,
  });

  useEffect(() => {
    if (updateIdx && popupAnnouncement?.message) {
      toaster.info(popupAnnouncement?.message);
      void queryClient.invalidateQueries({
        queryKey: [getPopupAnnouncementListQueries.QUERY_KEY],
      });
      setPath('main');
    }
  }, [popupAnnouncement]);

  const [popupForm, setPopupForm] = useState<IPopupAnnouncementForm>(
    updateIdx && popupAnnouncement?.data
      ? convertToForm(popupAnnouncement.data)
      : DEFAULT_POPUP_FORM,
  );

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewItem, setPreviewItem] = useState<IPopupAnnouncement[]>([]);

  useEffect(() => {
    setPopupForm(
      updateIdx && popupAnnouncement?.data
        ? convertToForm(popupAnnouncement.data)
        : DEFAULT_POPUP_FORM,
    );
  }, [updateIdx, popupAnnouncement?.data]);

  const createPopupMutation = useMutation({
    mutationFn: createPopupAnnouncement,
    onSuccess: () => {
      toaster.message(
        <Templates.Basic description={t(`${POPUP_DICTIONARY}.form.onsave`)} />,
      );
      void queryClient.invalidateQueries({
        queryKey: [getPopupAnnouncementListQueries.QUERY_KEY],
      });
      setIsNeedSave(false);
      setPath('main');
    },
    onError: (error) => {
      toaster.message(<Templates.Basic description={error.message} />);
    },
  });

  const updatePopupMutation = useMutation({
    mutationFn: updatePopupAnnouncement,
    onSuccess: () => {
      toaster.message(
        <Templates.Basic description={t(`${POPUP_DICTIONARY}.form.onsave`)} />,
      );
      void queryClient.invalidateQueries({
        queryKey: [getPopupAnnouncementListQueries.QUERY_KEY],
      });
      void queryClient.invalidateQueries({
        queryKey: [getPopupAnnouncementQueries.QUERY_KEY, updateIdx],
      });
      setUpdateIdx(null);
      setPath('main');
    },
    onError: (error) => {
      toaster.message(<Templates.Basic description={error.message} />);
    },
  });

  const fileUploadMutation = useMutation({
    mutationFn: uploadCommonFile,
    onSuccess: (response) => {
      const data = response as UploadResponse;
      if (!data || !data.filePath) {
        toaster.message(
          <Templates.Basic
            description={t(`${POPUP_DICTIONARY}.fileUploadFail`)}
          />,
        );
        return;
      }
      setIsNeedSave(true);
      setPopupForm((prev) => ({
        ...prev,
        attachFileList: prev.attachFileList.concat([
          {
            path: data.filePath,
            extension: data.fileExtension ?? '',
            size: data.fileSize ?? 0,
            name: data.fileName,
          },
        ]),
      }));
    },
    onError: (error) => {
      toaster.message(<Templates.Basic description={error.message} />);
    },
  });

  const handleOnChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    setIsNeedSave(true);
    const { name, value } = e.target;

    setPopupForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleOnChangeIsUse = (value?: boolean) => {
    setIsNeedSave(true);
    setPopupForm((prev) => ({
      ...prev,
      isUse: !!value,
    }));
  };

  const isContentEmpty = () => {
    const bodyText = DEXT5.getBodyTextValue(editorId).trim() as string;
    const bodyHTML = DEXT5.getBodyValue(editorId).trim() as string;

    const hasTextContent = bodyText.length > 0;
    const hasMediaContent = /<img|<iframe/.test(bodyHTML);

    return !(hasTextContent || hasMediaContent);
  };

  const formValidate = (form: IPopupAnnouncementForm) => {
    if (form.title.length < 1 || form.title.length > 64) {
      toaster.info(
        <Templates.Basic
          description={t(`${POPUP_DICTIONARY}.form.titleLengthMessage`)}
        />,
      );
      return false;
    }

    if (isContentEmpty()) {
      toaster.info(
        <Templates.Basic
          description={t(`${POPUP_DICTIONARY}.form.emptyContentMessage`)}
        />,
      );
      return false;
    }

    if (
      form.noticePeriodType === 'WEEKLY_REPEAT' &&
      form.dayOfWeeks?.length === 0
    ) {
      toaster.info(
        <Templates.Basic
          description={t(`${POPUP_DICTIONARY}.form.emptyDayOfWeeks`)}
        />,
      );
      return false;
    }
    return true;
  };

  const handleOnSave = () => {
    const data = {
      ...popupForm,
      content: DEXT5.getBodyValue(editorId) as string,
    };
    if (formValidate(data)) {
      if (updateIdx) {
        updatePopupMutation.mutate({
          data,
          popupId: updateIdx,
        });
      } else {
        createPopupMutation.mutate({
          data,
        });
      }
    }
  };

  const handleOnClickPreview = () => {
    const data = {
      ...popupForm,
      content: DEXT5.getBodyValue(editorId) as string,
    };
    if (formValidate(data)) {
      setPreviewItem([
        {
          ...data,
          createdDate: new Date().toString(),
          id: -1,
          creatorId: -1,
          creatorName: 'preview',
          creatorPositionId: -1,
          creatorPositionName: '',
        },
      ]);
      setPreviewOpen(true);
    }
  };

  const fileUploader = useRef<HTMLInputElement>(null);

  const handleOnUploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      for (let i = 0; i < e.target.files.length; i++) {
        if (e.target.files[i]) {
          fileUploadMutation.mutate(e.target.files[i]!);
        }
      }
    }
  };

  const handleOnDeleteFile = (index: number) => {
    setPopupForm((prev) => ({
      ...prev,
      attachFileList: prev.attachFileList.filter((item, idx) => idx !== index),
    }));
  };

  return (
    <>
      <FormField title={t(`${POPUP_DICTIONARY}.form.title`)}>
        <input
          className={styles.Input}
          type="text"
          maxLength={64}
          value={popupForm.title}
          onChange={handleOnChange}
          name="title"
        />
      </FormField>

      <FormField title={t(`${POPUP_DICTIONARY}.form.content`)}>
        <Dext5Editor.Provider>
          <div className="flex-1 w-full">
            {updateIdx && popupForm.content && (
              <Dext5Editor.MemoizedDextEditor
                id={editorId}
                content={popupForm.content}
                className="flex-1"
              />
            )}
            {!updateIdx && (
              <Dext5Editor.MemoizedDextEditor
                id={editorId}
                content={popupForm.content}
                className="flex-1"
              />
            )}
          </div>
        </Dext5Editor.Provider>
      </FormField>

      <FormField title={t(`${POPUP_DICTIONARY}.form.fileUpload`)}>
        <input
          type="file"
          multiple
          ref={fileUploader}
          style={{ display: 'none' }}
          onChange={handleOnUploadFile}
        />

        <Button
          size="md"
          variant="outline"
          shape="rect"
          colorset="level2"
          onClick={() => fileUploader.current?.click()}
        >
          <ClipIcon />
          {t(`${POPUP_DICTIONARY}.form.fileSelect`)}
        </Button>

        <div className={styles.FileList}>
          {popupForm.attachFileList.map((file, idx) => (
            <div key={idx} className={styles.File}>
              <FileItem
                fileName={file.name}
                fileSize={file.size}
                fileType={file.extension}
              />
              <Button onClick={() => handleOnDeleteFile(idx)}>
                <CloseIcon size={16} />
              </Button>
            </div>
          ))}
        </div>
        {/* TODO: 파일 관리 추가 */}
      </FormField>

      <FormField title={t(`${POPUP_DICTIONARY}.form.period`)}>
        <FormAnnouncementPeroid
          popupForm={popupForm}
          setPopupForm={setPopupForm}
        />
      </FormField>

      <FormField title={t(`${POPUP_DICTIONARY}.form.target`)}>
        <FormAnnouncementTarget
          popupForm={popupForm}
          setPopupForm={setPopupForm}
        />
      </FormField>
      <FormField title={t(`${POPUP_DICTIONARY}.form.device`)}>
        <select
          className={styles.Select}
          name="device"
          id="popup-device"
          value={popupForm.device}
          onChange={handleOnChange}
        >
          {Object.entries(POPUP_DEVICES).map(([value, label]) => (
            <option key={value} value={value}>
              {t(label)}
            </option>
          ))}
        </select>
      </FormField>

      <FormField title={t(`${POPUP_DICTIONARY}.form.option`)}>
        <select
          className={styles.Select}
          name="option"
          id="popup-option"
          value={popupForm.option}
          onChange={handleOnChange}
        >
          {Object.entries(POPUP_OPTIONS).map(([value, label]) => (
            <option key={value} value={value}>
              {t(label)}
            </option>
          ))}
        </select>
      </FormField>

      <FormField title={t(`${POPUP_DICTIONARY}.form.use`)}>
        <div className="flex gap-2">
          <Switch
            id="is-use"
            checked={popupForm.isUse}
            onChange={(checked) => handleOnChangeIsUse(checked)}
          />
          <label htmlFor="is-use">
            {t(`${POPUP_DICTIONARY}.${popupForm.isUse ? 'use' : 'notUse'}`)}
          </label>
        </div>
      </FormField>
      <div className="flex justify-center gap-2">
        <Button
          variant="outline"
          shape="rect"
          size="md"
          colorset="level2"
          onClick={handleOnClickPreview}
        >
          {t(`${POPUP_DICTIONARY}.form.preview`)}
        </Button>
        <Button
          variant="solid"
          shape="rect"
          size="md"
          colorset="level1"
          onClick={handleOnSave}
        >
          {t(`${POPUP_DICTIONARY}.form.send`)}
        </Button>
      </div>

      <PopupAnnouncementDialog
        previewMode
        open={previewOpen}
        setOpen={setPreviewOpen}
        list={previewItem}
        setList={setPreviewItem}
      />
    </>
  );
}

export default PopupAnnouncementForm;
