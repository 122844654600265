import * as Icon16 from '@daouoffice/ui/lib/icons/dop/16';
import * as Icon from '@daouoffice/ui/lib/icons/dop/24';
import { clsx } from 'clsx';
import Link from 'next/link';
import { useContext } from '../../../Context';
import { useContextValues } from './Context';
import { EditAppInfo } from './types';

export default function EditApp({
  uid,
  name,
  code,
  tooltip,
  count,
  url,
  isChild = false,
  childList,
}: EditAppInfo) {
  const {
    searchKeyword,
    openNodes,
    inSearchCloseNodes,
    selectedNode,
    setOpenNodes,
    setInSearchCloseNodes,
    setSelectedNode,
  } = useContextValues();
  const ctx = useContext();

  const isLeaf = childList ? childList.length === 0 : true;

  const isSearching = !(searchKeyword === '');

  const isOpened =
    (isSearching ? !inSearchCloseNodes.has(uid) : openNodes.has(uid)) &&
    !isLeaf;

  const tooltipComponent = tooltip ? (
    <div className="has_tooltip">
      <i className="icon ic_medium">
        <Icon.QuestionCircleIcon />
      </i>
      <span className="tips bottom">{tooltip}</span>
    </div>
  ) : (
    <></>
  );

  const countBadge =
    count && count !== 0 ? (
      <div className="badge rounded-3xl !bg-[#E6F6F9]">
        <span className="!text-[#0798AE] text-[11px] !font-normal">
          {count}
        </span>
      </div>
    ) : (
      <></>
    );

  const handleOnClick = () => {
    if (isLeaf) {
      setSelectedNode(uid);
      ctx.setSelectedAppName(name);
      ctx.setSelectedAppId(uid);
      ctx.setSelectedAppCode(code ?? '');
      return;
    }
    const oldNodes = isSearching ? inSearchCloseNodes : openNodes;
    const newNodes = new Set(oldNodes);

    if (oldNodes.has(uid)) {
      newNodes.delete(uid);
    } else {
      newNodes.add(uid);
    }

    isSearching ? setInSearchCloseNodes(newNodes) : setOpenNodes(newNodes);
  };

  return (
    <li key={uid} className="accordion_list mb-[4px]">
      <Link
        className={clsx('accordion_menu rounded mb-[4px]', {
          '!bg-[#dddddd50] !rounded-lg': isLeaf && selectedNode === uid,
        })}
        href={url ? url : ''}
        onClick={(e) => {
          e.preventDefault();
          handleOnClick();
        }}
      >
        <div className="accordion_title">
          <span
            className={clsx(
              'txt body_medium text-[#262626] !font-normal text-sm',
              {
                bold: !isChild,
                regular: isChild,
              },
            )}
          >
            {name}
          </span>
          {tooltipComponent}
        </div>
        {countBadge}
        {childList && childList.length ? (
          <i className="icon ic_xs ic_flip">
            {isOpened ? <Icon16.UnFlipIcon /> : <Icon16.FlipIcon />}
          </i>
        ) : (
          <></>
        )}
      </Link>
      {isOpened ? (
        <ul className="wrap_accordion_sub">
          {childList?.map((child) => {
            return (
              <EditApp
                key={child.uid}
                uid={child.uid}
                name={child.name}
                tooltip={child.tooltip}
                count={child.count}
                url={child.url}
                icon={child.icon}
                isChild={true}
                childList={child.childList}
              />
            );
          })}
        </ul>
      ) : (
        <></>
      )}
    </li>
  );
}
