import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { IconButton } from '@daouoffice/ui/lib/foundation/Button';
import {
  ChevronDownIcon,
  ChevronUpIcon,
} from '@daouoffice/ui/lib/icons/heroicons/24/outline';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import {
  DATA_USING_STORAGES_KEY,
  getDataUsingStorages,
} from '../apis/getDataUsingStorages';
import { StorageGroup } from './StorageGroup';

export function DataUsingApps() {
  const [open, setOpen] = useState(false);
  const { data, error } = useQuery({
    queryKey: [DATA_USING_STORAGES_KEY],
    queryFn: async () => await getDataUsingStorages(),
  });

  const { t } = useTranslation('component');

  if (error) {
    console.error(`Error: GlobalConfig > DataUsingApps : ${error.message}`);
  }

  const titleOfPortals = (portalType: string) => {
    if (portalType === 'EMPLOYEE') return t('service.employeePortal.name');
    if (portalType === 'BUSINESS') return t('service.businessPortal.name');
    if (portalType === 'NONE')
      return t('globalconfig.dataManagement.currentUsage.etc');
    return t('service.unknownPortal.name');
  };

  return (
    <div className="w-full mt-[32px] px-[48px] py-[40px] border border-solid border-[#E6E7EA] rounded-[8px] text-[#333333]">
      <div className="flex">
        <p className="text-[22px] font-[500] flex-grow">
          {t('globalconfig.dataManagement.currentUsage.appsInUse')}
        </p>
        <IconButton title="open" onClick={() => setOpen(!open)}>
          {open ? (
            <ChevronDownIcon className="size-[24px]" />
          ) : (
            <ChevronUpIcon className="size-[24px]" />
          )}
        </IconButton>
      </div>
      {open && (
        <div className="w-full">
          {data &&
            data.map((datum) => (
              <StorageGroup
                key={datum.portalType}
                title={titleOfPortals(datum.portalType)}
                count={datum.count ?? 0}
                storages={datum.apps ?? []}
                isCommon={datum.portalType === 'NONE'}
              />
            ))}
        </div>
      )}
    </div>
  );
}
