import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';

export interface Props {
  keyword?: string;
  onChangeKeyword?: (keyword: string) => void;
  placeholder?: string;
  className?: string;
}

export function SearchBar({
  keyword,
  onChangeKeyword,
  placeholder,
  className,
}: Props) {
  const { t } = useTranslation('component');
  return (
    <input
      className={clsx(
        'w-full min-h-[32px] text-[12px] text-[#1C1C1C] leading-[12px] tracking-[-0.48px] font-[500] placeholder-[#C5C7CA] border border-solid border-[#CFD1D4] rounded-[4px] px-[12px] py-[10px]',
        className,
      )}
      placeholder={placeholder ?? t('shared.filter.searchBar.placeholder')}
      value={keyword}
      onChange={(e) => onChangeKeyword && onChangeKeyword(e.target.value)}
    />
  );
}

export default SearchBar;
