import { fetch } from '@dop-ui/react/shared/lib/fetch/client';
import { EMP_PORTAL_APP_MANAGEMENT_API } from '../../../../constants';
import { InUseAppInfo, UpdateAppInfo } from '../../../types';
import { BaseResponse } from '../../../../types';

interface InUseAppInfoDivByPurchaseType {
  defaultCompanyAppUsageList: InUseAppInfo[];
  purchasedCompanyAppUsageList: InUseAppInfo[];
}

export const QUERY_KEY =
  'GlobalConfig.BasicManagement.EmpAppManagement.InUseAppsInfo';

export async function getAppsUsageStatus() {
  const response = await fetch(EMP_PORTAL_APP_MANAGEMENT_API, {
    method: 'GET',
    headers: {
      'X-Referer-Info': window.location.host,
    },
  });

  const responseData =
    (await response.json()) as BaseResponse<InUseAppInfoDivByPurchaseType>;

  return responseData.data;
}

export async function updateAppStatus(updateAppInfo: UpdateAppInfo) {
  const response = await fetch(EMP_PORTAL_APP_MANAGEMENT_API, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(updateAppInfo),
  });

  if (!response.ok) {
    throw new Error('Failed to update app status');
  }
}
