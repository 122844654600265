import * as Popover from '../../popover';
import { Provider } from './context';

interface Props extends Popover.RootProps {
  name: string;
  multiSelectable?: boolean;
  onChangeValues?: (values: Set<string>) => void;
}

export function Root({
  name,
  multiSelectable = false,
  children,
  onChangeValues,
  ...props
}: Props) {
  return (
    <Popover.Root {...props}>
      <Provider
        name={name}
        multiSelectable={multiSelectable}
        onChangeValues={onChangeValues}
      >
        {children}
      </Provider>
    </Popover.Root>
  );
}
