import { fetch } from '@dop-ui/react/shared/lib/fetch/client';
import { getShareUrl } from '../utils/get-share-url';

export interface IResponseBody {
  data: boolean;
}

export const QUERY_KEY = 'enableApprovalBetweenSitesQuery';

export async function getApprovalBetweenSitesStatus(
  companyGroupId: string | number,
  isEnabled: boolean,
) {
  try {
    const res = await fetch(getShareUrl(companyGroupId));

    if (!res.ok) {
      throw Error('Failed to fetch approval between sites status');
    }
    const { data } = (await res.json()) as IResponseBody;
    return data;
  } catch (error) {
    console.error(error);
    return isEnabled;
  }
}
