import { IconChevronDown, IconX } from '@dop-ui/icons/react/tabler/index';
import { clsx } from 'clsx';
import { Button } from '../../button';
import * as Popover from '../../popover';
import { useFilterValuesContext } from './context';

export interface Props extends Popover.TriggerProps {
  useCloseButton?: boolean;
  onClose?: () => void;
}

export function Trigger({
  useCloseButton,
  onClose,
  className,
  children,
  ...props
}: Props) {
  const { name, selectedOptions, selectedOptionLabels } =
    useFilterValuesContext();
  const options = Array.from(selectedOptions);

  const onCloseHandler = () => {
    onClose && onClose();
  };

  return (
    <Popover.Anchor>
      <Popover.Trigger {...props}>
        {children ? (
          children
        ) : (
          <div
            className={clsx(
              'flex items-center h-[40px] px-[8px] border border-solid border-[#D8D8D8] //border-[--color-border-field] rounded-[8px]',
              'text-[#999999] //text-[--color-text-level3] text-[14px] font-[400]',
              className,
            )}
          >
            {name}
            {options.length > 0 && (
              <>
                {' : '}
                <span className="ml-[4px] text-[#0798AE] //text-[--primary-color-text-level1]">
                  {options
                    .map((value) => selectedOptionLabels.get(value))
                    .join(', ')}
                </span>
              </>
            )}
            <IconChevronDown className="ml-[4px]" color="#1C1C1C" size={16} />
            {useCloseButton && (
              <Button
                onClick={onCloseHandler}
                className="border-l border-solid border-l-[#CFD1D4] pl-[8px] ml-[4px] h-full"
              >
                <IconX size={16} color="#1C1C1C" />
              </Button>
            )}
            {}
          </div>
        )}
      </Popover.Trigger>
    </Popover.Anchor>
  );
}
