import { useAtom } from 'jotai';
import { PlusIcon } from '@heroicons/react/24/outline';
import * as Dialog from '@dop-ui/react/shared/ui/dialog';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { ScheduleInfo } from '../../types';
import { scheduleDialogOpenAtom } from '../../store/dialog_open_atom';
import { Content } from './content';
import { useEffect } from 'react';

interface Props {
  currentYear: number;
  info?: ScheduleInfo;
  onClose?: () => void;
}

export function ScheduleDialog({ currentYear, info, onClose }: Props) {
  const { t } = useTranslation('component');
  const [isOpen, setIsOpen] = useAtom(scheduleDialogOpenAtom);

  const handleOpenChange = (open: boolean) => {
    if (!open) onClose && onClose();
    setIsOpen(open);
  };

  useEffect(() => {
    if (info) {
      setIsOpen(true);
    }
  }, [info, setIsOpen]);

  return (
    <Dialog.Root open={isOpen} onOpenChange={handleOpenChange}>
      <Dialog.Trigger asChild>
        <div className="h-[40px] p-[4px] flex items-center gap-[4px] cursor-pointer">
          <PlusIcon className="p-[2px] size-[28px]" />
          <p className="text-[#363636] //text-[--color-text-level1] text-[14px] font-[400]">
            {t('globalconfig.basicManagement.companySchedule.schedule.add')}
          </p>
        </div>
      </Dialog.Trigger>
      <Dialog.Primitives.Portal>
        <Dialog.Overlay />
        <Dialog.Content
          className="w-[500px] bg-white px-[24px] py-[24px] rounded-[16px]"
          showCloseButton={false}
          useAutoClose={false}
        >
          <Content
            currentYear={currentYear}
            originInfo={info}
            onClose={onClose}
          />
        </Dialog.Content>
      </Dialog.Primitives.Portal>
    </Dialog.Root>
  );
}
