import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Button } from '@dop-ui/react/shared/ui/button';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { useContext } from '../provider';
import { ApprovalContents } from './appvoal-contents';
import { AssetContents } from './asset-contents';
import { BoardContents } from './board-contents';
import { CalendarContents } from './calendar-contents';

export function AppContents() {
  const { t } = useTranslation('component');
  const { selectedAppCode, setSelectedAppCode } = useContext();

  const getTitle = () => {
    if (selectedAppCode === 'dop-board') {
      return t('globalconfig.multiCompanyManagement.appShare.board.title');
    } else if (selectedAppCode === 'dop-asset') {
      return t('globalconfig.multiCompanyManagement.appShare.asset.title');
    } else if (selectedAppCode === 'dop-calendar') {
      return t('globalconfig.multiCompanyManagement.appShare.calendar.title');
    } else {
      return t('globalconfig.multiCompanyManagement.appShare.approval.title');
    }
  };

  const contentsRouter = () => {
    if (selectedAppCode === 'dop-board') {
      return <BoardContents />;
    } else if (selectedAppCode === 'dop-asset') {
      return <AssetContents />;
    } else if (selectedAppCode === 'dop-calendar') {
      return <CalendarContents />;
    } else {
      return <ApprovalContents />;
    }
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-center gap-2">
        <Button onClick={() => setSelectedAppCode(null)}>
          <ArrowLeftIcon className="size-6 storke-[#363636]" />
          <h2 className="text-[#363636] text-[20px] font-bold leading-[--font-multi-line-height-XLarge] tracking-[--font-letter-spacing-XLarge]">
            {getTitle()}
          </h2>
        </Button>
      </div>
      {/* Contents 영역 */}
      <div>{contentsRouter()}</div>
    </div>
  );
}

export default AppContents;
