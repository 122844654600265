import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import {
  CalendarIcon,
  ChevronRightIcon,
  ClockIcon,
  ListBulletIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';
import { AppInfo } from '../types';
import { useActionsContext } from './provider';

export function MainContents() {
  const { t } = useTranslation('component');
  const { setSelectedAppCode } = useActionsContext();
  const appList: AppInfo[] = [
    {
      appCode: 'dop-board',
      title: t('globalconfig.multiCompanyManagement.appShare.board.title'),
      description: t(
        'globalconfig.multiCompanyManagement.appShare.board.description',
      ),
      icon: <ListBulletIcon className="size-6 stroke-[#363636]" />,
    },
    {
      appCode: 'dop-asset',
      title: t('globalconfig.multiCompanyManagement.appShare.asset.title'),
      description: t(
        'globalconfig.multiCompanyManagement.appShare.asset.description',
      ),
      icon: <ClockIcon className="size-6 stroke-[#363636]" />,
    },
    {
      appCode: 'dop-calendar',
      title: t('globalconfig.multiCompanyManagement.appShare.calendar.title'),
      description: t(
        'globalconfig.multiCompanyManagement.appShare.calendar.description',
      ),
      icon: <CalendarIcon className="size-6 stroke-[#363636]" />,
    },
    {
      appCode: 'dop-approval',
      title: t('globalconfig.multiCompanyManagement.appShare.approval.title'),
      description: t(
        'globalconfig.multiCompanyManagement.appShare.approval.description',
      ),
      icon: <PencilSquareIcon className="size-6 stroke-[#363636]" />,
    },
  ];

  return (
    <div>
      {appList.map((appInfo, index) => (
        <button
          key={`frag-${appInfo.appCode}-${index}`}
          className="w-full"
          onClick={() => setSelectedAppCode(appInfo.appCode)}
        >
          <div
            key={`${appInfo.appCode}-${index}`}
            className="py-7 flex items-center justify-between"
          >
            <div className="flex items-center">
              {appInfo.icon}
              <span className="ms-6 w-[142px] text-[16px] text-[#363636] text-elipsis font-medium leading-[--font-multi-line-height-Regular] tracking-[--font-letter-spacing-Regular]">
                {appInfo.title}
              </span>
              <span className="text-[14px] text-[#999] font-normal leading-normal tracking-[-0.28px]">
                {appInfo.description}
              </span>
            </div>
            <ChevronRightIcon className="size-6 stroke-[#363636]" />
          </div>
          {index !== appList.length - 1 && (
            <div
              key={`divider-${appInfo.appCode}-${index}`}
              className="ms-[48px] border-b border-b-[#D8D8D8]"
            />
          )}
        </button>
      ))}
    </div>
  );
}

export default MainContents;
