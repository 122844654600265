import { Switch } from '@daouoffice/ui';
import * as TablePrimitives from '@daouoffice/ui/lib/labs/Table/index';
import {
  SortChevron,
  columnDefGenerator,
} from '@daouoffice/ui/lib/labs/Table/utils/columnDefGenerator';
import { useMessageDialog } from '@dop-ui/react/features/message-dialog';
import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { InformationCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { formatDateString } from '../../../utils/dateTimeUtils';
import * as deleteMobileDeviceMutation from '../../api/deleteMobileDeviceMutation';
import * as mobileAppAccessListQuery from '../../api/mobileAppAccessListQuery';
import * as mobileAppBlockMutation from '../../api/mobileAppBlockMutation';
import {
  AccessType,
  BaseResponseBody,
  Direction,
  MobileInfo,
  SearchType,
} from '../../types';

export interface Props {
  type: AccessType;
}

export function DeviceList({ type }: Props) {
  // TODO: sort 및 searchBar select 기능 추가 필요
  const { t } = useTranslation('component');
  const { info } = useToastMessage();
  const { confirm } = useMessageDialog();
  const queryClient = useQueryClient();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageOffet, setCurrentPageOffset] = useState(20);
  const [keyword, setKeyword] = useState('');
  const [orderType, setOrderType] = useState<string>('name');
  const [searchType, setSearchType] = useState<SearchType>('name');
  const [direction, setDirection] = useState<Direction>('asc');
  const searchOptions = new Map<SearchType, string>([
    [
      'name',
      t('globalconfig.secureManagement.mobileBlockConfig.table.field.name'),
    ],
    [
      'loginId',
      t('globalconfig.secureManagement.mobileBlockConfig.table.field.loginId'),
    ],
    [
      'DEVICE_MODEL',
      t(
        'globalconfig.secureManagement.mobileBlockConfig.table.field.deviceModel',
      ),
    ],
  ]);
  const getQueryString = () => {
    const query = new URLSearchParams();
    query.append('page', currentPage.toString());
    query.append('size', pageOffet.toString());
    query.append('orderType', orderType);
    query.append('searchType', searchType);
    query.append('direction', direction);
    query.append('keyword', keyword);
    return query.toString();
  };

  const days = {
    monday: t('days.monday'),
    tuesday: t('days.tuesday'),
    wednesday: t('days.wednesday'),
    thursday: t('days.thursday'),
    friday: t('days.friday'),
    saturday: t('days.saturday'),
    sunday: t('days.sunday'),
  };

  const {
    data: defaultDeviceData,
    error,
    refetch,
  } = useQuery({
    queryKey: [mobileAppAccessListQuery.QUERY_KEY, currentPage, pageOffet],
    queryFn: () =>
      mobileAppAccessListQuery.getMobileAppAccessStatus(getQueryString()),
  });
  const [deviceData, setDeviceData] = useState<BaseResponseBody<MobileInfo>>(
    defaultDeviceData ?? {
      elements: [],
      hasElements: false,
      page: {
        pageNumber: 0,
        pageSize: 0,
        totalPage: 0,
        totalElements: 0,
      },
    },
  );

  const deleteMobileDeviceAccess = useMutation({
    mutationKey: [deleteMobileDeviceMutation.MUTATION_KEY],
    mutationFn: (id: number) =>
      deleteMobileDeviceMutation.deleteMobileDevice(id),
    onSuccess: async () => {
      info(t('secureManagement.mobileDeviceAccessConfig.toast.delete'));
      await queryClient.invalidateQueries({
        queryKey: [mobileAppAccessListQuery.QUERY_KEY],
      });
    },
  });

  const updateMobileDeviceAccess = useMutation({
    mutationKey: [mobileAppBlockMutation.MUTATION_KEY],
    mutationFn: async ({
      isBlocked,
      deviceId,
    }: {
      isBlocked: boolean;
      deviceId: number;
    }) => {
      await mobileAppBlockMutation.updateMobileAppBlockStatus(
        isBlocked,
        deviceId,
      );
      return true;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [mobileAppAccessListQuery.QUERY_KEY],
      });
    },
  });

  useEffect(() => {
    refetch().catch(console.error);
  }, [
    type,
    currentPage,
    pageOffet,
    keyword,
    orderType,
    searchType,
    direction,
    refetch,
  ]);

  useEffect(() => {
    setDeviceData(
      defaultDeviceData ?? {
        elements: [],
        hasElements: false,
        page: {
          pageNumber: 0,
          pageSize: 0,
          totalPage: 0,
          totalElements: 0,
        },
      },
    );
  }, [defaultDeviceData]);

  useEffect(() => {
    setCurrentPage(0);
  }, [keyword, pageOffet]);

  if (error) {
    return <></>;
  }

  const onKeywordChange = (keyword: string) => {
    setKeyword(keyword);
  };

  const onOffsetChange = (offset: number) => {
    setCurrentPageOffset(offset);
  };

  const onSortChangeHandler = (direction: Direction, value: string) => {
    setDirection(direction);
    setOrderType(value);
  };

  const columnDefs: ColumnDef<MobileInfo>[] = [
    columnDefGenerator<MobileInfo>(
      'userName',
      t('globalconfig.secureManagement.mobileBlockConfig.table.field.name'),
      'name',
      orderType,
      onSortChangeHandler,
      direction,
    ),
    columnDefGenerator<MobileInfo>(
      'email',
      t('globalconfig.secureManagement.mobileBlockConfig.table.field.loginId'),
      'loginId',
      orderType,
      onSortChangeHandler,
      direction,
    ),
    columnDefGenerator<MobileInfo>(
      'deviceModel',
      t(
        'globalconfig.secureManagement.mobileBlockConfig.table.field.deviceModel',
      ),
    ),
    {
      accessorKey: 'lastConnected',
      id: 'lastConnected',
      header: () => (
        <div className="flex items-center py-3 text-center gap-1">
          {t(
            'globalconfig.secureManagement.mobileBlockConfig.table.field.lastConnected',
          )}
          {'last_connected' && (
            <SortChevron
              filterValue={'last_connected'}
              selectedValue={orderType}
              direction={direction ?? 'asc'}
              onChange={onSortChangeHandler}
            />
          )}
        </div>
      ),
      cell: (value: CellContext<MobileInfo, unknown>) => (
        <div className="py-3 text-center">
          {formatDateString(days, value.renderValue() as string)}
        </div>
      ),
    },
    columnDefGenerator<MobileInfo>(
      'deviceId',
      t('globalconfig.secureManagement.mobileBlockConfig.table.field.deviceId'),
    ),
    {
      id: 'blockYn',
      accessorKey: 'blockYn',
      header: () => (
        <div className="flex items-center py-3 justify-center gap-1">
          {t(
            'globalconfig.secureManagement.mobileBlockConfig.table.field.blockYn',
          )}
          <div className="has_tooltip">
            <InformationCircleIcon className="size-4" />
            <span className="tips top !whitespace-pre-wrap text-left w-[396px]">
              {t('globalconfig.secureManagement.mobileBlockConfig.infoTooltip')}
            </span>
          </div>
        </div>
      ),
      cell: (info: CellContext<MobileInfo, unknown>) => {
        return (
          <div className="flex justify-center gap-4">
            <Switch
              id={info.row.original.deviceId}
              checked={info.row.original.blockYn}
              onChange={() =>
                requestHandler(info.row.original.id, !info.row.original.blockYn)
              }
            />
            <TrashIcon
              className="size-6 fill-transparent"
              onClick={() => deleteHandler(info.row.original.id)}
            />
          </div>
        );
      },
    },
  ];

  const deleteHandler = (id: number) => {
    const handleConfirm = async () => {
      const requestConfirm = (
        <div className="w-full flex flex-col gap-4">
          <h2 className="text-[18px] text-black font-medium">
            {t(
              'globalconfig.secureManagement.mobileBlockConfig.messageDialog.deleteDevice.title',
            )}
          </h2>
          <span className="text-[14px] text-black font-normal whitespace-normal">
            {t(
              'globalconfig.secureManagement.mobileBlockConfig.messageDialog.deleteDevice.contents',
            )}
          </span>
        </div>
      );

      return await confirm(requestConfirm);
    };

    handleConfirm()
      .then((res) => res && deleteMobileDeviceAccess.mutate(id))
      .catch(console.error);
  };

  const requestHandler = (deviceId: number, isBlocked: boolean) => {
    const handleConfirm = async (isBlocked: boolean) => {
      const requestConfirm = (
        <div className="w-full flex flex-col gap-4">
          <h2 className="text-[18px] text-black font-medium">
            {t(
              isBlocked
                ? 'globalconfig.secureManagement.mobileBlockConfig.messageDialog.blockAllow.title'
                : 'globalconfig.secureManagement.mobileBlockConfig.messageDialog.blockDisallow.title',
            )}
          </h2>
          <span className="text-[14px] text-black font-normal whitespace-normal">
            {t(
              isBlocked
                ? 'globalconfig.secureManagement.mobileBlockConfig.messageDialog.blockallow.contents'
                : 'globalconfig.secureManagement.mobileBlockConfig.messageDialog.blockDisallow.contents',
            )}
          </span>
        </div>
      );

      return await confirm(requestConfirm);
    };
    handleConfirm(isBlocked)
      .then(
        (res) =>
          res && updateMobileDeviceAccess.mutate({ deviceId, isBlocked }),
      )
      .catch(console.error);
  };

  return (
    <TablePrimitives.Root<MobileInfo>
      contents={deviceData.elements}
      columnDefs={columnDefs}
    >
      <div className="flex w-full justify-between mb-[16px]">
        <div className="flex flex-col">
          <TablePrimitives.SearchBar
            className="inline-block w-[240px]"
            onKeywordChange={onKeywordChange}
          >
            <select
              className="w-[50px] border-none mb-[2px] shirink-0 text-ellipsis"
              value={searchType}
              onChange={(e) => setSearchType(e.target.value as SearchType)}
            >
              {Array.from(searchOptions).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </select>
          </TablePrimitives.SearchBar>
          {keyword && (
            <div className="pt-3 text-[#81A9FC]">{`${deviceData.elements.length}${t('globalconfig.common.table.search.result')}`}</div>
          )}
        </div>
        <div className="spacer flex-grow" />
        <div className="flex justify-between items-end gap-1">
          <TablePrimitives.SizeSelector
            className="w-[52px]"
            onChangeTableSize={onOffsetChange}
          />
        </div>
      </div>
      <TablePrimitives.Contents
        className="text-center mt-4"
        emptyNotice={
          <div className="w-full py-[60px] flex items-center justify-center ">
            <span className="w-full text-center font-normal leading-norml=al text-[#888] text-ellipsis">
              {t(
                'globalconfig.secureManagement.mobileBlockConfig.table.nodata',
              )}
            </span>
          </div>
        }
      />
      {deviceData?.elements?.length === 0 && deviceData.elements.length > 0 && (
        <TablePrimitives.Pagination
          className="mt-[16px]"
          currentPage={currentPage}
          totalPage={deviceData?.page?.totalPage ?? 0}
          onNextPage={setCurrentPage}
          onPreviousPage={setCurrentPage}
          onClickPage={setCurrentPage}
          onFirstPage={() => setCurrentPage(0)}
          onLastPage={() =>
            setCurrentPage(
              deviceData?.page.totalPage ? deviceData.page.totalPage - 1 : 0,
            )
          }
        />
      )}
    </TablePrimitives.Root>
  );
}

export default DeviceList;
