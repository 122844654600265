import { formatDateWithLocale } from '@dop-ui/react/shared/lib/date/with-locale';
import { useTranslation } from 'react-i18next';
import styles from './popup-announcement-list.module.css';
import { useQuery } from '@tanstack/react-query';
interface Props {
  date: Date | string;
}
function CreatedDate({ date }: Props) {
  const { i18n } = useTranslation('component');

  const { data } = useQuery({
    queryKey: [date, 'YYYY-MM-DD (ddd)'],
    queryFn: () =>
      formatDateWithLocale({
        date: date,
        format: 'YYYY-MM-DD (ddd)',
        locale: i18n.language,
      }),
  });

  return <p className={styles.TableCell}>{data}</p>;
}
export default CreatedDate;
