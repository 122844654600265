import { useAtom } from 'jotai';
import * as Dialog from '@dop-ui/react/shared/ui/dialog';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { ScheduleInfo } from '../../types';
import { deleteScheduleDialogOpenAtom } from '../../store/dialog_open_atom';
import { Content } from './content';
import { Button } from '@dop-ui/react/shared/ui/button';

interface Props {
  scheduleInfo: ScheduleInfo;
  onCompleteDelete: () => void;
}

export function DeleteScheduleDialog({
  scheduleInfo,
  onCompleteDelete,
}: Props) {
  const { t } = useTranslation('component');
  const [isOpen, setIsOpen] = useAtom(deleteScheduleDialogOpenAtom);

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger asChild>
        <Button colorset="level2" variant="solid" size="medium" shape="rect">
          <p className="text-[14px] font-[400]">{t('dialog.delete')}</p>
        </Button>
      </Dialog.Trigger>
      <Dialog.Primitives.Portal>
        <Dialog.Overlay />
        <Dialog.Content
          className="w-[500px] bg-white px-[24px] py-[24px] rounded-[16px]"
          showCloseButton={false}
          useAutoClose={false}
        >
          <Content
            scheduleInfo={scheduleInfo}
            onCompleteDelete={onCompleteDelete}
          />
        </Dialog.Content>
      </Dialog.Primitives.Portal>
    </Dialog.Root>
  );
}
