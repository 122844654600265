import { clsx } from 'clsx';
import { useEffect, useState } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { RoleType } from '../../types';
import { OrganizationCode } from './types';
import * as subSelectionApi from './apis/sub-selections';

interface Props {
  roleType: RoleType;
  narrowMode: boolean;
  value?: OrganizationCode;
  onSubCodeChange: (orgCode?: OrganizationCode) => void;
}

export function ClassSubList({
  roleType,
  value,
  narrowMode,
  onSubCodeChange,
}: Props) {
  const { t } = useTranslation('component');
  const [subCodeList, setSubCodeList] = useState<OrganizationCode[]>([]);
  const [currentSubCode, setCurrentSubCode] = useState(value);

  const { data: organizationCodeList } = useSuspenseQuery({
    queryKey: [subSelectionApi.QUERY_KEY, roleType],
    queryFn: async () => subSelectionApi.getSubSelectionList(roleType),
  });

  useEffect(() => {
    if (
      organizationCodeList === undefined ||
      organizationCodeList.length === 0
    ) {
      setSubCodeList([]);
      setCurrentSubCode(undefined);
      onSubCodeChange(undefined);
    } else {
      setSubCodeList(organizationCodeList);
      onSubCodeChange(organizationCodeList[0]);
      setCurrentSubCode(organizationCodeList[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationCodeList]);

  useEffect(() => {
    setCurrentSubCode(value);
  }, [value]);

  if (roleType === 'USER' || roleType === 'DEPARTMENT') return;

  return (
    <select
      className={clsx(
        'h-[32px] px-[8px] rounded-[4px]',
        {
          'w-[120px]': narrowMode,
          'w-[200px]': !narrowMode,
        },
        {
          'bg-[#F2F2F2] border-none text-[#808080]':
            !subCodeList || subCodeList?.length <= 0,
          'border border-solid border-[#D8D8D8]':
            subCodeList && subCodeList?.length > 0,
        },
      )}
      value={currentSubCode?.id}
      onChange={(e) => {
        const orgCode = subCodeList.find(
          (code) => code.id === Number(e.target.value),
        );
        onSubCodeChange(orgCode);
      }}
    >
      {subCodeList &&
        subCodeList.map((subCode) => (
          <option key={subCode.id} value={subCode.id}>
            {subCode.name}
          </option>
        ))}
      {!subCodeList ||
        (subCodeList.length === 0 && (
          <option key={'no-data'}>
            {t(
              'globalconfig.multiCompanyManagement.appShare.classManagement.category.subClass.noData',
            )}
          </option>
        ))}
    </select>
  );
}
