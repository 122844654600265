import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { useEffect, useState } from 'react';
import { keywordSearchedFuseResult } from '../../../../../../../../../lib/search';
import { useContextValues } from './Context';
import EditApp from './EditApp';
import { SearchInput } from './SearchInput';
import { EditAppInfo } from './types';

export interface Props {
  data: EditAppInfo[];
}

export function Content({ data: defaultData }: Props) {
  const { t } = useTranslation('component');
  const [data, setData] = useState(defaultData);
  const { searchKeyword, setSearchKeyword } = useContextValues();

  const matchFilteredMenuInfo = (
    item: EditAppInfo,
    matchKeywords: Set<string>,
  ) => {
    if (
      (item.childList &&
        item.childList.length === 0 &&
        matchKeywords.has(item.name)) ||
      matchKeywords.has(item.name)
    ) {
      return item;
    }

    if (!item.childList) return;

    const childs: EditAppInfo[] =
      item.childList
        ?.map((child) => {
          const result = matchFilteredMenuInfo(child, matchKeywords);
          return result;
        })
        ?.filter((info): info is EditAppInfo => info !== undefined) ?? [];

    if (childs && childs.length !== 0)
      return {
        ...item,
        childList: childs,
      };
  };

  const filteredData =
    searchKeyword === ''
      ? data
      : keywordSearchedFuseResult<EditAppInfo>(data, searchKeyword, [
          'name',
          'childList.name',
        ])
          .map((fuseResult) => {
            const matchedKeywords: Set<string> = new Set();

            fuseResult &&
              fuseResult?.matches?.forEach((match) => {
                if (match.value) matchedKeywords.add(match.value);
              });

            return matchFilteredMenuInfo(fuseResult.item, matchedKeywords);
          })
          .filter((item) => item);

  useEffect(() => {
    setData(defaultData);
  }, [defaultData]);

  return (
    <>
      <SearchInput
        title={t('globalconfig.secureManagement.adminManagement.search.input')}
        placeholder={t(
          'globalconfig.secureManagement.adminManagement.search.input',
        )}
        value={searchKeyword}
        onValueChange={setSearchKeyword}
      />
      <ul className="wrap_accordion_list mt-4">
        {filteredData.map((item) => {
          return item ? (
            <EditApp
              key={item.uid}
              uid={item.uid}
              name={item.name}
              tooltip={item.tooltip}
              count={item.count}
              url={item.url}
              code={item.code}
              icon={item.icon}
              childList={item.childList}
            />
          ) : (
            <></>
          );
        })}
      </ul>
    </>
  );
}
