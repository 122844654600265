import type {
  DeviceTypes,
  IPopupAnnouncementForm,
  PopupOptions,
  Week,
} from './types';

export const DEFAULT_OFFSET = 20;
export const POPUP_DICTIONARY =
  'globalconfig.basicManagement.popupAnnouncement';

export const POPUP_OPTIONS: Record<PopupOptions, string> = {
  DO_NOT_OPEN_ANYMORE: `${POPUP_DICTIONARY}.options.doNotOpenAnymore`,
  DO_NOT_OPEN_FOR_A_WEEK: `${POPUP_DICTIONARY}.options.doNotOpenForAWeek`,
  DO_NOT_OPEN_FOR_A_DAY: `${POPUP_DICTIONARY}.options.doNotOpenForADay`,
  SHOW_EVERY_TIME: `${POPUP_DICTIONARY}.options.showEveryTime`,
};

export const POPUP_DEVICES: Record<DeviceTypes, string> = {
  ALL: `${POPUP_DICTIONARY}.device.ALL`,
  MOBILE: `${POPUP_DICTIONARY}.device.MOBILE`,
  WEB: `${POPUP_DICTIONARY}.device.WEB`,
};

export const DEFAULT_POPUP_FORM: IPopupAnnouncementForm = {
  content: '',
  title: '',
  attachFileList: [],
  device: 'ALL',
  startDate: new Date(),
  endDate: new Date(new Date().setDate(new Date().getDate() + 6)),
  isUse: false,
  option: 'DO_NOT_OPEN_ANYMORE',
  targetList: [],
  targetType: 'ALLOW',
  noticePeriodType: 'SPECIFIC_DATE',
  repeatValue: 1,
  dayOfWeeks: [],
  targetDay: 1,
  targetDayOfWeek: 'MONDAY',
  targetWeek: 1,
};

export const WEEK_DATA: Week[] = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
];

export const LAST_DAY = 32;
export const LAST_WEEK = 6;
